import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

const PrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn = false;
      try {
        let sessionData = await Auth.currentAuthenticatedUser();
        let groups;
        if (sessionData) {
          groups =
            sessionData.signInUserSession.accessToken.payload["cognito:groups"];
          if (groups.includes(rest.allowedGroup)) {
            isLoggedIn = true;
          }
        }
      } catch (error) {}
      if (!isLoggedIn) {
        setLoggedInStatus(false);
      }
    }
    checkLoggedInStatus();
  }, []);

  let [isLoggedIn, setLoggedInStatus] = useState(true);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
