import React, { Component } from "react";
import styles from "./Services.module.scss";
let calendarImage = require("../../../images/calendar.svg");
let clipboardImage = require("../../../images/clipboard.svg");
let constructionImage = require("../../../images/construction-and-tools.svg");
let humanImage = require("../../../images/human.svg");
let notesImage = require("../../../images/notes.svg");
let toDoImage = require("../../../images/to-do-list.svg");

export default function Services() {
  const services = [
    {
      name: <>Payroll & Tax Administration</>,
      image: notesImage,
      description:
        "Get access to a payroll system that you need to obtain professionally maintained records and conduct payroll on time, every time.",
    },
    {
      name: <>Risk Management & Safety</>,
      image: constructionImage,
      description:
        "We want to help you facilitate a work environment that is safe and OSHA compliant. If the time comes for you to deal with a worker’s compensation claim, we will handle the delicate process, so you can maintain a professional relationship with your injured employee.",
    },
    {
      name: <>Human Resources</>,
      image: humanImage,
      description: `Human resources is a huge responsibility for all businesses, regardless of the size. With human resource outsourcing, ${
        process.env.REACT_APP_MODE == "beta" ? " PEO2 " : "SolveHR  "
      } becomes part of your team, providing the expertise, services and tools you need to manage your most important resource — your employees.`,
    },
    {
      name: <>Compliance</>,
      image: toDoImage,
      description:
        "We solve your payroll tax headaches by administering the preparation and submission of payroll tax reports. We stay current on all relevant taxation and regulatory issues to ensure your company stays compliant and avoids penalties.",
    },
    {
      name: <>Benefits Management & Administration</>,
      image: clipboardImage,
      description:
        "We solve benefits administration giving you the ability to offer great employee benefits so you can attract and retain high-quality employees. We simplify all HR paperwork, ensuring HIPAA compliance, from insurance and benefits packages.",
    },
    {
      name: <>Time & Attendance</>,
      image: calendarImage,
      description:
        "From a time management system to calculating and processing payroll, we provide a complete solution for business of all sizes. Our range of scheduling, time card and attendance solutions include both onsite and complete outsourced options for keeping track of your greatest resource.",
    },
  ];
  return (
    <div className={styles.services}>
      <h2>Services</h2>
      <div className={styles.serviceList}>
        {services.map((service, index) => {
          return (
            <div className={styles.service} key={index}>
              <div className={styles.image}>
                <img src={service.image} alt={service.name} />
              </div>
              <div className={styles.info}>
                <h5>{service.name}</h5>
                <div className={styles.description}>{service.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
