import React, { useState } from "react";
import styles from "./RequestCallBack.module.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../../config";

let submitForm = (email, name, phone, type, setMessage) => {
  if (email && phone) {
    axios
      .post(
        awsUrl2 + "/api/sendCallbackMail",
        JSON.stringify({ email, name, phone, type })
      )
      .then(async (response) => {
        setMessage("Thank you for reaching out. We'll be in touch shortly.");
      });
  } else {
    setMessage("Please enter you email and phone number.");
  }
};

let setState = (value, func, setMessage) => {
  setMessage();
  func(value);
};

export default function RequestCallBack(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("Payroll");
  const [message, setMessage] = useState();

  return (
    <Row className={styles.requestCallBack}>
      <Container>
        <Col>
          <Row>
            <Col md={4} className={styles.ctaBlock}>
              <h4>request a call back.</h4>
              <p>
                Would you like to speak to one of our Business Consultants over
                the phone? Just submit your details and we’ll be in touch
                shortly. You can also email us if you would prefer.
              </p>
            </Col>
            <Col md={8} className={styles.formBlock}>
              <p>I would like to discuss:</p>
              <Form as={Row}>
                <Form.Group as={Col} md={6}>
                  <select
                    onChange={(e) =>
                      setState(e.target.value, setType, setMessage)
                    }
                  >
                    <option value="Payroll">Payroll</option>
                    <option value="Benefits">Benefits</option>
                    <option value="Time & Attendance">Time & Attendance</option>
                    <option value="Tax & Compliance">Tax & Compliance</option>
                    <option value="Risk & Safety">Risk & Safety</option>
                    <option value="Request a Demo">Request a Demo</option>
                  </select>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <input
                    type="text"
                    placeholder="First name"
                    onKeyUp={(e) =>
                      setState(e.target.value, setName, setMessage)
                    }
                  ></input>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <input
                    type="text"
                    placeholder="Email Address"
                    onKeyUp={(e) =>
                      setState(e.target.value, setEmail, setMessage)
                    }
                  ></input>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <input
                    type="text"
                    placeholder="Phone number"
                    onKeyUp={(e) =>
                      setState(e.target.value, setPhone, setMessage)
                    }
                  ></input>
                </Form.Group>
                <Form.Group as={Col} md={12}>
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="submit"
                    onClick={() =>
                      submitForm(email, name, phone, type, setMessage)
                    }
                  />
                </Form.Group>
              </Form>
              {message && <p>{message}</p>}
            </Col>
          </Row>
        </Col>
      </Container>
    </Row>
  );
}
