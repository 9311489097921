import React, { Component } from "react";
import Allques from "../../subcompo/sections-quote/Allques";
import { updateQuesList } from "../../../utils/form_ques";
import { setUWQues } from "../../../utils/session_data";
import { quesList } from "../../../utils/ques_list";
import { submitQues } from "../../../utils/form_ques";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";

class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: quesList,
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.submitQues = submitQues.bind(this);
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
  }

  render() {
    const { quesList } = this.state;
    const { updatePopUpData } = this.props;
    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName = currProspect.companyName;
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className="container-fluid per98">
        <NewProgress />
        <div id="underWritingQues">
          <form>
            <div className="row no-gutters heading-row">
              <span className="formTitle title2">
                <b>
                  And finally, answers to some basic questions about your
                  business
                </b>
              </span>
              <p className="compName">{compName}</p>
            </div>
            {/* <hr /> */}
            <div className="table-responsive">
              <Allques
                quesList={quesList}
                setUWQues={this.setUWQues}
                updateQuesList={this.updateQuesList}
              />
            </div>
          </form>
          <div className="row">
            <div className="col-12 text-center">
              <button
                type="button"
                className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
                onClick={() => this.submitQues(quesList, updatePopUpData)}
              >
                Get Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormUnderQues;
