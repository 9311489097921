import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

const AntiPrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
      } catch (error) {}
      if (isLoggedIn) {
        setLoggedInStatus(true);
      }
    }
    checkLoggedInStatus();
  }, []);

  let [isLoggedIn, setLoggedInStatus] = useState(false);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/Quote", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default AntiPrivateRoute;
