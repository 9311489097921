import React, { Component } from "react";
import { Link } from "react-router-dom";

class Dropresources extends Component {
  render() {
    return (
      <div id="resDrop" className="container-fluid">
        <ul className="navDrop">
          <li className="linkLike">
            <a href="https://www.ncci.com/pages/default.aspx" target="_blank">
              <img src={require("../../images/icon_list_4.png")} alt="" /> NCCI
            </a>
          </li>
          <Link to="/faq">
            <li className="linkLike">
              <img src={require("../../images/icon_list_5.png")} alt="" /> FAQ
            </li>
          </Link>
        </ul>
      </div>
    );
  }
}

export default Dropresources;
