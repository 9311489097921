import React, { Component } from 'react';
const smallLoader = require('../../images/small_loader.gif');
class SmallLoader extends Component{
    render(){
        return(
            <div id='small-loader'>
               <img src={smallLoader} alt=''/>
            </div>
        )
    }
}
export default SmallLoader;