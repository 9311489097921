import React, { Component } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Form, Button } from "react-bootstrap";
import Newcode from "./Newcode";
import CompLoc from "./CompLoc";
import EmodQuestions from "./EmodQuestions";
import InputGroup from "react-bootstrap/InputGroup";
import {
  submitCompPro,
  stepOneGet,
  sendEmail,
  resendOTPMethod,
  verifyOTP,
  reset,
  setStateAndCity,
  getEmodStatesData,
} from "../../../utils/form_compPro";
import {
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
} from "../../../utils/common";
import {
  cookie,
  cookie2,
  setCookie,
  setProspectDetails,
  deleteAddress,
  setAddressDetails,
} from "../../../utils/session_data";
import NewProgress from "../../../newcomponent/common/NewProgress";
import $ from "jquery";
import SmallLoader from "../../common/SmallLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL, awsUrl, awsUrl2 } from "../../../config";
import store from "../../../redux/store/index";
import StateList from "../../common/StateList";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
const numeral = require("numeral");
const uuidv4 = require("uuid/v4");
let myConfig = new AWS.Config();
myConfig.update({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAJB3XUTJTLJMI4BHA",
    secretAccessKey: "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",
  },
});
var prospect, addressCount, loggedin;
class FormCompPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      role: "",
      selDateBus: null,
      selDateEff: moment(),
      selDateExp: moment().add(1, "year"),
      header: {},
      childrenLoc: {},
      uploadingFile: false,
      awaitingFile: false,
      error: false,
      errorMsg: "",
      urlSuccess: false,
      urlFail: false,
      basicInfoFail: false,
      stateInfoFail: false,
      signedUrl: "",
      companyProfile: {
        companyName: {},
        primaryContact: {},
        phoneNumber: {},
        emailId: {},
        effectiveDate: { value: moment() },
        healthQues: { value: true },
        fein: {},
      },
      emodQuestions: {},
      emodStatesData: {},
      otpVerified:
        sessionStorage.getItem("otpVerified") === "true" ? true : false,
    };
    this.setStateAndCity = setStateAndCity.bind(this);
  }

  addYearToDate = (dt) => {
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };
  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };
  handleDateChangeEff = (date) => {
    let { companyProfile } = this.state;
    if (date) {
      companyProfile.effectiveDate.value = date;
      this.setState({ companyProfile });
    }
  };
  handleDateChangeExp = (date) => {
    this.setState({
      selDateExp: date,
    });
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    stepOneGet();
    prospect = cookie();
    addressCount = cookie2();
    loggedin = sessionStorage.getItem("isLoggedIn");
    let rand = Math.random();
    let dupChildrenLoc = {};
    let initialCountOfAddress = 1;
    if (prospect && addressCount && loggedin) {
      initialCountOfAddress = addressCount;
    }
    for (let i = 0; i < initialCountOfAddress; i++) {
      let rand = Math.random();
      dupChildrenLoc[i] = {
        number: i,
        key: rand,
        classCodesInfo: { 0: {} },
        state: {},
        zipCode: {},
        cityName: {},
      };
    }
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
    $("#loader").css("display", "block");
  }

  // componentDidUpdate() {
  //   const { childrenLoc } = this.state;
  //   clearDelete(childrenLoc, "addressBlock");
  //   if (prospect && addressCount && loggedin) {
  //     setProspectDetails();
  //     setAddressDetails();
  //   }
  // }

  setInputData = (currProspect) => {
    let { childrenLoc, companyProfile, emodStatesData } = currProspect;

    if (!("healthQues" in companyProfile)) {
      companyProfile.healthQues = { value: true };
    }
    companyProfile.effectiveDate.value = moment(
      companyProfile.effectiveDate.value
    );
    for (let state in emodStatesData) {
      if ("date1" in emodStatesData[state]) {
        emodStatesData[state].date1.value = moment(
          emodStatesData[state].date1.value
        );
      }
      if ("date2" in emodStatesData[state]) {
        emodStatesData[state].date2.value = moment(
          emodStatesData[state].date2.value
        );
      }
    }
    this.setState({ childrenLoc, companyProfile, emodStatesData });
  };

  componentDidMount = () => {
    let mailCampaignUserCheck = checkMailCampaignUser();

    Auth.currentAuthenticatedUser().then((userTemp) => {
      if (userTemp) {
        sessionStorage.setItem("isLoggedIn", true);
        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_SALES_GROUP)
        ) {
          sessionStorage.setItem("otpVerified", true);
          this.setState({ otpVerified: true, salesLogin: true });
        }
      }
    });

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let campaignName = urlParams.get("campaignName");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain;
    let viewButton = urlParams.get("viewButton");
    if (user && !mailCampaignUserCheck) {
      $("#loader").css("display", "block");
      user = user.toLowerCase();
      let tempBusinessName = user.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = user.split("@")[0];
      } else {
        domain = business_name;
      }
      let currDomain = sessionStorage.getItem("domain");
      let timestamp = Math.floor(Date.now()).toString();
      sessionStorage.setItem("visitTimestamp", timestamp);
      sessionStorage.setItem("landingEmail", user);
      axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          let requestBody = {};
          let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
          if (domainResp.data && domainResp.data.domain) {
            domain = domainResp.data.domain;
          }
          if (response.data) {
            sessionStorage.setItem("emailCampaignUser", true);
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  emailId: { value: lineObj.user_email_id },
                  fein: { value: lineObj.fein },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };
                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let indicationCost = lineObj.carrier_location_data.total;

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem("domain", domain);
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
                requestBody = {
                  email: user.toLowerCase(),
                  domain: domain.toLowerCase(),
                  timestamp: timestamp,
                  payrollData: childrenLoc,
                  companyProfile,
                  indicationCost,
                  emodStatesData,
                  campaignName,
                  preventCompanyProfileUpdate: true,
                };

                if (user && !viewButton) {
                  axios
                    .post(
                      awsUrl2 + "/api/updateSolveTrackingData",
                      JSON.stringify(requestBody)
                    )
                    .then((res) => {
                      axios
                        .post(
                          awsUrl2 +
                            "/api/updateEngagedWithEmailCampaign/" +
                            domain,
                          JSON.stringify(domain)
                        )
                        .finally(() => {
                          $("#loader").css("display", "none");
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      $("#loader").css("display", "none");
                    });
                } else {
                  $("#loader").css("display", "none");
                  window.location.reload();
                }
              }
            }
          } else {
            if (currDomain !== domain) {
              sessionStorage.setItem("domain", domain);
              axios
                .get(awsUrl2 + "/api/getDomainInputData/" + domain)
                .then((response) => {
                  if (response.status === 200 && response.data.email_specific) {
                    sessionStorage.setItem("emailCampaignUser", true);
                    this.props.updateMailCampaignUser(true);
                    let {
                      companyProfile,
                      childrenLoc,
                      emodStatesData,
                      indicationCost,
                      email_specific,
                    } = response.data;

                    if (email_specific) {
                      if (user in email_specific) {
                        companyProfile = email_specific[user].companyProfile;
                      } else {
                        companyProfile.emailId.value = user;
                      }
                    }

                    let currProspect = {
                      childrenLoc,
                      companyProfile,
                      emodStatesData,
                    };

                    if (!("healthQues" in companyProfile)) {
                      companyProfile.healthQues = { value: true };
                    }

                    sessionStorage.setItem(
                      "currProspect",
                      JSON.stringify(currProspect)
                    );
                    sessionStorage.setItem("formStage", "one");

                    requestBody = {
                      email: user.toLowerCase(),
                      domain: domain.toLowerCase(),
                      timestamp: timestamp,
                      payrollData: childrenLoc,
                      companyProfile,
                      indicationCost,
                      emodStatesData,
                      campaignName,
                      emailSpecific: email_specific,
                    };

                    if (user && !viewButton) {
                      axios
                        .post(
                          awsUrl2 + "/api/updateSolveTrackingData",
                          JSON.stringify(requestBody)
                        )
                        .then((res) => {
                          axios
                            .post(
                              awsUrl2 +
                                "/api/updateEngagedWithEmailCampaign/" +
                                domain,
                              JSON.stringify(domain)
                            )
                            .finally(() => {
                              requestBody = {
                                domain,
                                formStage: "one",
                                email: user,
                              };
                              axios
                                .post(
                                  awsUrl2 +
                                    "/api/updateSolveTrackingDataGeneric/updateFormStage",
                                  JSON.stringify(requestBody)
                                )
                                .then((res) => {
                                  console.log("updated formstage one");
                                })
                                .catch((error) => {
                                  console.log("error", error);
                                })
                                .finally(() => {
                                  this.setInputData(currProspect);
                                  $("#loader").css("display", "none");
                                });
                            });
                        })
                        .catch((err) => {
                          $("#loader").css("display", "none");
                        });
                    } else {
                      $("#loader").css("display", "none");
                    }
                  } else {
                    $("#loader").css("display", "none");
                  }
                })
                .catch((err) => {
                  $("#loader").css("display", "none");
                });
            } else {
              $("#loader").css("display", "none");
            }
          }
        })
        .catch((err) => {
          $("#loader").css("display", "none");
        });
    } else if (sessionStorage.getItem("currProspect")) {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      this.setInputData(currProspect);
    } else {
      $("#loader").css("display", "none");
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data, that) => {
    console.log("data", data);
    sessionStorage.setItem("acordData", JSON.stringify(data));
    const { basic_information, state_details, location_details } = data;
    if (
      basic_information.eff_date &&
      moment(basic_information.eff_date).isValid()
    ) {
      this.setState({ selDateEff: moment(basic_information.eff_date) });
    }
    if (
      basic_information.exp_date &&
      moment(basic_information.exp_date).isValid()
    ) {
      this.setState({ selDateExp: moment(basic_information.exp_date) });
    }
    if (basic_information.fein) {
      this.setState({ fein: basic_information.fein });
    }
    if (basic_information.company_name) {
      this.setState({ company_name: basic_information.company_name });
    }
    if (basic_information.phone_number) {
      this.setState({ phone_number: basic_information.phone_number });
    }

    if (basic_information.desc) {
      $("#description").val(basic_information.desc);
    }

    if (Object.entries(location_details).length > 0) {
      for (let locNumber in location_details) {
        that.onAddChildLoc(location_details[locNumber]);
      }
      that.onrmvChild(0);
    }
  };

  uploadFile = (event) => {
    if (this.state.file) {
      var s3 = new AWS.S3(myConfig);
      // $("#loader").css("display", "block");
      this.setState({ uploadingFile: true });
      this.setState({
        error: false,
        urlSuccess: false,
        urlFail: false,
        basicInfoFail: false,
        stateInfoFail: false,
        signedUrl: "",
        awaitingFile: false,
      });

      console.log("Clicked", this.state.file[0]);
      const that = this;
      event.preventDefault();

      const formData = new FormData();
      formData.append("file", this.state.file[0]);

      axios
        .post(
          "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev/api/fileUploadAcord",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response);

          that.setState({ uploadingFile: false });
          that.setState({ awaitingFile: true });
          const fileName = response.data.fileName;
          const folder = fileName.split(".")[0];
          const resultFilePath = `${folder}/finalResponse.json`;
          console.log("++succ", resultFilePath);
          var params = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
            $waiter: {
              maxAttempts: 180,
              delay: 5,
            },
          };
          var getParams = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
          };
          s3.waitFor("objectExists", params, function(err, data) {
            if (err) {
              console.log("file not yet ready", err);
              that.setState({ awaitingFile: false });
              that.setState({ error: true, errorMsg: "File not yet ready!" });
            } else {
              console.log("file created successfully", data); // successful response
              that.setState({ awaitingFile: false });
              s3.getSignedUrl("getObject", getParams, function(err, url) {
                if (err) {
                  $("#loader").css("display", "none");
                  console.log("Error", err);
                  that.setState({
                    error: true,
                    errorMsg: "Error in retreiving the file",
                  });
                } else {
                  console.log("The URL is", url);
                  fetch(url)
                    .then((response) => response.json())
                    .then((data) => {
                      console.log("++++data", data);
                      if (Object.entries(data).length === 0) {
                        that.setState({ urlFail: true });
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length !== 0
                      ) {
                        that.setState({ basicInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length !== 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ stateInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ urlFail: true });
                      } else {
                        that.setState({ urlSuccess: true, signedUrl: url });
                        that.autoFillAcordData(data, that);
                      }
                      $("#loader").css("display", "none");
                    })
                    .catch((error) => {
                      console.log(`Failed because: ${error}`);
                      $("#loader").css("display", "none");
                    });
                }
              });
            }
          });
        })
        .catch((error) => {
          $("#loader").css("display", "none");
          this.setState({ uploadingFile: false });
          console.log("++error", error);
          // handle your error
        });
    }
  };

  updateCompanyProfile = (event, fieldName) => {
    let { companyProfile } = this.state;
    companyProfile[fieldName] = { value: event.target.value };
    this.setState({ companyProfile });
  };

  updateLocationInfo = (value, locationIndex, fieldName, classCodeIndex) => {
    let { childrenLoc, emodStatesData } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
      this.setState({ childrenLoc });
    } else {
      childrenLoc[locationIndex][fieldName] = { value: value };
      if (fieldName === "state") {
        let stateList = [];
        for (let addressBlockNo in childrenLoc) {
          if (childrenLoc[addressBlockNo].state.value) {
            stateList.push(
              childrenLoc[addressBlockNo].state.value.toLowerCase()
            );
          }
        }
        if (stateList.length > 0) {
          let statesData = {
            ncciStates: [],
            actingNcciStates: [],
            indBureauStates: [],
          };
          axios
            .post(
              awsUrl2 + "/api/getExpModStateCategory",
              JSON.stringify({ states: stateList })
            )
            .then((response) => {
              statesData = response.data;
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              let newEmodData = getEmodStatesData(statesData, emodStatesData);
              this.setState({ childrenLoc, emodStatesData: newEmodData });
            });
        } else {
          emodStatesData = {};
          this.setState({ childrenLoc, emodStatesData });
        }
      } else {
        this.setState({ childrenLoc });
      }
    }
  };

  removeClassCode = (locationIndex, classCodeIndex) => {
    let { childrenLoc } = this.state;
    delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
    this.setState({ childrenLoc });
  };

  updateClassCodesInfo = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc } = this.state;
    childrenLoc[locationIndex].classCodesInfo[classCodeIndex][fieldName] = {
      value: value,
    };
    this.setState({ childrenLoc });
  };

  updateLocationError = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        fieldName
      ].error = value;
    } else {
      childrenLoc[locationIndex][fieldName].error = value;
    }
    this.setState({ childrenLoc });
  };

  resetClassCodeDescriptionData = (locationIndex) => {
    let { childrenLoc } = this.state;
    for (let index in childrenLoc[locationIndex].classCodesInfo) {
      if (
        childrenLoc[locationIndex].classCodesInfo[index].classCodeDescription
      ) {
        delete childrenLoc[locationIndex].classCodesInfo[index]
          .classCodeDescription.value;
      }
    }
    this.setState({ childrenLoc });
  };

  checkCompanyProfileError = () => {
    let { companyProfile } = this.state;
    let error = false;
    let field;
    for (let fieldName in companyProfile) {
      if (
        (![
          "healthQues",
          "fein",
          "original_effective_date",
          "industry",
          "job_title",
        ].includes(fieldName) &&
          !companyProfile[fieldName].value) ||
        companyProfile[fieldName].error
      ) {
        companyProfile[fieldName].error = true;
        if (!field) field = fieldName;
        error = true;
      }

      // if (fieldName === "primaryContact") {
      //   var reg = /^[a-z A-Z\b]+$/;
      //   var isValid = reg.test(companyProfile[fieldName].value);
      //   if (!isValid) {
      //     companyProfile[fieldName].error = true;
      //     if (!field) field = fieldName;
      //     error = true;
      //   }
      // }
    }
    this.setState({ companyProfile });
    return [error, field];
  };

  checkLocationInfoError = () => {
    let { childrenLoc } = this.state;
    let error = false;
    let locationKeys = ["state"];
    let classCodeKeys = ["classCodeDescription", "ft", "pt", "payroll"];
    let field;
    let index = 0;
    for (let addressBlockNo in childrenLoc) {
      locationKeys.forEach((fieldName) => {
        if (
          !childrenLoc[addressBlockNo][fieldName].value ||
          childrenLoc[addressBlockNo][fieldName].error
        ) {
          childrenLoc[addressBlockNo][fieldName].error = true;
          error = true;
          if (!field) field = "officeLoc" + index;
        }
      });
      for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
        classCodeKeys.forEach((fieldName) => {
          if (
            !(
              fieldName in
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
            )
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ] = { error: true };
            error = true;
            if (!field) field = "officeLoc" + index;
          } else if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].value ||
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error = true;
            if (!field) field = "officeLoc" + index;
            error = true;
          }
        });
      }
      index++;
    }
    this.setState({ childrenLoc });
    return [error, field];
  };

  checkEmodInfoError = () => {
    let { emodStatesData } = this.state;
    let error = false;
    for (let state in emodStatesData) {
      if (emodStatesData[state].response) {
        if (!emodStatesData[state].rate1) {
          error = true;
          emodStatesData[state].rate1 = { value: "", error: true };
        } else if (
          !emodStatesData[state].rate1.value ||
          isNaN(emodStatesData[state].rate1.value)
        ) {
          error = true;
          emodStatesData[state].rate1.error = true;
        }
        if (emodStatesData[state].displayMore) {
          if (!emodStatesData[state].rate2) {
            error = true;
            emodStatesData[state].rate2 = { value: "", error: true };
          } else if (
            !emodStatesData[state].rate2.value ||
            isNaN(emodStatesData[state].rate2.value)
          ) {
            error = true;
            emodStatesData[state].rate2.error = true;
          }
        }
      }
    }

    return error;
  };

  validEmail = (e) => {
    let { companyProfile, otpVerified } = this.state;
    var email = e.target.value;
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(email);
    if (!isValid && email !== "") {
      companyProfile.emailId.error = true;
    } else {
      if (!otpVerified) {
        axios
          .post(awsUrl2 + "/api/sendOtpMail", { email })
          .then((res) => {
            this.setState({
              otpMessage:
                "A 4-digit verification code has been sent to your email.",
            });
            console.log("sent OTP successfully");
          })
          .catch((error) => {
            console.log("error while sending OTP");
          });
      }
    }
    this.setState({ companyProfile });
  };

  verifyOtp(email, otp) {
    axios
      .post(awsUrl2 + "/api/verifyOtp", { email, otp })
      .then((res) => {
        if (res.data === "otp_verified") {
          this.setState({
            otpMessage: "Email verified successfully.",
            otpVerified: true,
          });
          this.fetchAndFillDetails();
        } else if (res.data === "otp_expired") {
          this.setState({
            otpMessage: "OTP expired.",
          });
        } else if (res.data === "invalid_otp") {
          this.setState({
            otpMessage: "Invalid OTP.",
          });
        } else if (res.data === "otp_not_generated") {
          this.setState({
            otpMessage: "OTP not generated.",
          });
        }
      })
      .catch((error) => {
        console.log("error while sending OTP");
        this.setState({
          otpMessage: "Unable to verify OTP.",
        });
      });
  }

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  submitCompPro = async () => {
    const { childrenLoc, companyProfile, emodStatesData } = this.state;
    let mailCampaignUserCheck = checkMailCampaignUser();
    let [companyProfileError, companyfield] = this.checkCompanyProfileError();
    let [locationInfoError, locationInfoField] = this.checkLocationInfoError();
    let emodInfoError = this.checkEmodInfoError();
    let currProspect;

    if (companyProfileError) {
      document.getElementById(companyfield).focus();
    } else if (locationInfoError) {
      document
        .getElementById(locationInfoField)
        .scrollIntoView({ behavior: "smooth" });
    }

    if (!companyProfileError && !locationInfoError && !emodInfoError) {
      $("#loader").css("display", "block");
      currProspect = {
        childrenLoc: childrenLoc,
        companyProfile: companyProfile,
        emodStatesData: emodStatesData,
      };

      if (mailCampaignUserCheck) {
        try {
          let user = companyProfile.emailId.value;
          let domain;
          try {
            domain = await this.getDomainFromEmail(user);
          } catch (error) {
            console.log("error in getting domain", error);
          }

          let requestBody = {
            domain,
            formStage: "two",
            email: user,
          };

          await axios
            .post(
              awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateFormStage",
              JSON.stringify(requestBody)
            )
            .then((res) => {
              console.log("updated formstage two");
            })
            .catch((error) => {
              console.log("error", error);
            });
        } catch (error) {
          console.log("error", error);
        }
      }

      sessionStorage.setItem("otpVerified", "true");
      sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
      sessionStorage.setItem("formStage", "two");
      window.location.reload();
    }
  };

  getDomainFromEmail = (user) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let response;
        let generalDomainNames = [
          "gmail",
          "yahoo",
          "aol",
          "mac",
          "cox",
          "frontier",
          "msn",
          "gammana",
          "hotmail",
          "pacbell",
          "att",
          "sbcglobal",
          "windstream",
          "verizon",
          "comcast",
          "ampcocontracting",
        ];
        let tempBusinessName = user.split("@")[1].split(".");
        tempBusinessName.pop();
        let business_name = tempBusinessName.join(".");
        if (generalDomainNames.includes(business_name)) {
          response = user.split("@")[0];
        } else {
          response = business_name;
        }

        let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
        if (domainResp.data && domainResp.data.domain) {
          response = domainResp.data.domain;
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  fetchAndFillDetails = async () => {
    try {
      $("#loader").css("display", "block");
      let user = this.state.companyProfile.emailId.value;

      await axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          if (response.data) {
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  fein: { value: lineObj.fein },
                  emailId: { value: lineObj.user_email_id },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };

                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
              }
            }
            $("#loader").css("display", "none");
            window.location.reload();
          } else {
            let domain;
            try {
              domain = await this.getDomainFromEmail(user);
            } catch (error) {
              console.log("error in getting domain", error);
            }

            sessionStorage.setItem("domain", domain);
            axios
              .get(awsUrl2 + "/api/getDomainInputData/" + domain)
              .then((response) => {
                if (response.status === 200 && response.data.email_specific) {
                  let {
                    companyProfile,
                    childrenLoc,
                    emodStatesData,
                    indicationCost,
                    email_specific,
                  } = response.data;

                  if (email_specific) {
                    if (user in email_specific) {
                      companyProfile = email_specific[user].companyProfile;
                    } else {
                      companyProfile.emailId.value = user;
                    }
                  }

                  companyProfile.effectiveDate.value = moment(
                    companyProfile.effectiveDate.value
                  );

                  console.log("companyProfile: ", companyProfile);
                  console.log("emodStatesData: ", emodStatesData);

                  for (let emodKey in emodStatesData) {
                    if (emodStatesData[emodKey].date1.value)
                      emodStatesData[emodKey].date1.value = moment(
                        emodStatesData[emodKey].date1.value
                      );
                    if (emodStatesData[emodKey].date2.value)
                      emodStatesData[emodKey].date2.value = moment(
                        emodStatesData[emodKey].date2.value
                      );
                  }

                  if (!("healthQues" in companyProfile)) {
                    companyProfile.healthQues = { value: true };
                  }

                  let currProspect = {
                    childrenLoc,
                    companyProfile,
                    emodStatesData,
                  };

                  sessionStorage.setItem(
                    "currProspect",
                    JSON.stringify(currProspect)
                  );
                  this.setState({
                    childrenLoc,
                    companyProfile,
                    emodStatesData,
                  });
                }
                $("#loader").css("display", "none");
              });
          }
          sessionStorage.setItem("otpVerified", "true");
        })
        .catch((error) => {
          console.log("error in data retrieval");
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in GET button click");
    }
  };

  updateHealthDropDown(event) {
    let { companyProfile } = this.state;
    companyProfile.healthQues.value =
      event.target.value === "true" ? true : false;
    this.setState({ companyProfile });
  }

  render() {
    const {
      companyProfile,
      emodStatesData,
      childrenLoc,
      otpVerified,
      otpMessage,
      otpValue,
      salesLogin,
    } = this.state;
    let index = 0;
    let mailCampaignUserCheck = checkMailCampaignUser();
    const titleText = mailCampaignUserCheck
      ? "Please verify the below details and update as necessary."
      : "Please provide the following information to get a quote";
    var isLoggedIn =
      sessionStorage.getItem("isLoggedIn") === undefined ||
      sessionStorage.getItem("isLoggedIn") === null
        ? false
        : sessionStorage.getItem("isLoggedIn");
    let locationCount = 1;

    let viewOnly = sessionStorage.getItem("viewOnly")
      ? JSON.parse(sessionStorage.getItem("viewOnly"))
      : false;

    try {
      locationCount = Object.keys(childrenLoc).length;
    } catch (e) {}
    return (
      <div id="compPro" className="container-fluid active per98">
        <NewProgress />
        <div id="compProfile">
          {/* <form>
            {!isLoggedIn && (
              <p id="existUser">
                Already an existing user?{" "}
                <Link className="prominent" to={"/Login"}>
                  Sign In
                </Link>
              </p>
            )}
          </form> */}
          <form>
            <div className="formTitle">
              <h3>
                <b>{titleText}</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-sm-5 grey">
                <Form.Group>
                  <label for="companyName" className="mandatory">
                    Company Name
                  </label>
                  <input
                    value={companyProfile.companyName.value}
                    id="companyName"
                    type="text"
                    style={
                      companyProfile.companyName.error
                        ? { borderColor: "red" }
                        : {}
                    }
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "companyName")
                    }
                    autoComplete="off"
                    className="rectangle1"
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div className="col-sm-4">
                <Form.Group>
                  <label
                    for="primaryContact"
                    title="Primary Contact (Name)"
                    className="mandatory"
                  >
                    Primary Contact (Name)
                  </label>
                  <input
                    value={companyProfile.primaryContact.value}
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "primaryContact")
                    }
                    style={
                      companyProfile.primaryContact.error
                        ? { borderColor: "red" }
                        : {}
                    }
                    id="primaryContact"
                    type="text"
                    // onKeyUp={onlyAlpha}
                    autoComplete="off"
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div className="col-sm-3">
                <Form.Group>
                  <label
                    for="effectiveDate"
                    title="Effective Date"
                    className="mandatory"
                  >
                    Effective Date
                  </label>
                  <span id="effectiveDate">
                    <DatePicker
                      selected={companyProfile.effectiveDate.value}
                      onChange={this.handleDateChangeEff}
                      showYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                    />
                  </span>
                  <p className="errMsg" />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <Form.Group>
                  <label for="phoneNumber" className="mandatory">
                    Phone Number
                  </label>
                  <NumberFormat
                    value={companyProfile.phoneNumber.value}
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "phoneNumber")
                    }
                    style={
                      companyProfile.phoneNumber.error
                        ? { borderColor: "red" }
                        : {}
                    }
                    autoComplete="off"
                    id="phoneNumber"
                    format="(###) ###-####"
                    type="text"
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>
              {true && (
                <div className="col-sm-6 col-lg-9">
                  <div className="row">
                    <div className="col-lg-8 relDiv">
                      <Form.Group>
                        <label for="emailId" className="mandatory">
                          Email Address
                        </label>
                        <input
                          value={companyProfile.emailId.value}
                          onChange={(event) => {
                            if (!salesLogin) {
                              this.setState({
                                otpVerified: false,
                                otpMessage: "",
                              });
                            }
                            this.updateCompanyProfile(event, "emailId");
                          }}
                          disabled={mailCampaignUserCheck}
                          style={
                            companyProfile.emailId.error
                              ? { borderColor: "red" }
                              : {}
                          }
                          id="emailId"
                          type="email"
                          onBlur={this.validEmail}
                          autoComplete="off"
                        />
                        {!mailCampaignUserCheck && (
                          <span style={{ fontSize: "" }}>{otpMessage}</span>
                        )}
                      </Form.Group>
                    </div>
                    {!mailCampaignUserCheck && !otpVerified && (
                      <div className="col-lg-4">
                        <Form.Group>
                          <label for="otp" className="mandatory">
                            One-Time Password
                          </label>
                          <InputGroup>
                            <Form.Control
                              id="otp"
                              type="text"
                              autoComplete="off"
                              value={otpValue}
                              onChange={(e) =>
                                this.setState({ otpValue: e.target.value })
                              }
                              disabled={viewOnly}
                            />
                            <InputGroup.Append>
                              <Button
                                onClick={() =>
                                  this.verifyOtp(
                                    companyProfile.emailId.value,
                                    otpValue
                                  )
                                }
                                className="btn btn-primary btn-sm"
                                disabled={viewOnly}
                              >
                                Verify
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    )}
                    {salesLogin && (
                      <div className="col-lg-4" style={{ marginTop: "1.3rem" }}>
                        <Form.Group>
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={(event) => {
                              event.preventDefault();
                              this.fetchAndFillDetails();
                            }}
                          >
                            Get
                          </button>
                        </Form.Group>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <Form.Group>
                  <label for="fein" className="mandatory">
                    FEIN
                  </label>
                  <input
                    value={companyProfile.fein ? companyProfile.fein.value : ""}
                    onChange={(event) => {
                      this.updateCompanyProfile(event, "fein");
                    }}
                    style={
                      companyProfile.fein && companyProfile.fein.error
                        ? { borderColor: "red" }
                        : {}
                    }
                    id="fein"
                    type="text"
                    autoComplete="off"
                    onInput={(event) => {
                      event.target.value = event.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1");
                    }}
                    onBlur={(event) => {
                      var value = event.target.value.toString().trim();
                      value = value.split("-").join("");
                      var len = value.length,
                        dummystr = "000000000";

                      if (len > 0 && len < 9) {
                        value = dummystr.slice(0, 9 - len) + "" + value;
                        companyProfile.fein.value =
                          value[0] + value[1] + "-" + value.slice(2);
                        companyProfile.fein.errMsg = "";
                      } else if (len > 9) {
                        companyProfile.fein.errMsg =
                          "FEIN needs to be 9 digits";
                        companyProfile.fein.error = true;
                      } else if (len === 0) {
                        companyProfile.fein.value = undefined;
                        companyProfile.fein.errMsg = "";
                      } else {
                        companyProfile.fein.value =
                          value[0] + value[1] + "-" + value.slice(2);
                        companyProfile.fein.errMsg = "";
                      }
                      this.setState({ companyProfile });
                    }}
                  />
                  <p className="errMsg">
                    {companyProfile.fein &&
                    companyProfile.fein.errMsg &&
                    companyProfile.fein.errMsg !== ""
                      ? companyProfile.fein.errMsg
                      : ""}
                  </p>
                </Form.Group>
              </div>
              <div className="col-sm-6" style={{ maxWidth: "fit-content" }}>
                <Form.Group>
                  <Form.Label
                    for="healthQues"
                    className="mandatory"
                    title="Are you also looking for Health Benefits?"
                  >
                    Are you also looking for Health Benefits?
                  </Form.Label>
                  <select
                    value={companyProfile.healthQues.value}
                    id="healthQues"
                    type="boolean"
                    onChange={(event) => this.updateHealthDropDown(event)}
                    autoComplete="off"
                    className="form-control"
                  >
                    <option key={"Yes"} value={true}>
                      {"Yes"}
                    </option>
                    <option key={"No"} value={false}>
                      {"No"}
                    </option>
                  </select>
                </Form.Group>
              </div>
            </div>
            <div className="calculator mt-4">
              <div id="allLocations">
                <span className="formTitle">
                  <h4 className="company-locations">
                    <b>Exposure Details</b>
                  </h4>
                </span>
                <div id="addressDiv">
                  {Object.values(childrenLoc).map((e) => {
                    index++;
                    return (
                      <>
                        <CompLoc
                          index={index}
                          key={e.key}
                          locationInfo={e}
                          updateLocationInfo={this.updateLocationInfo}
                          setStateAndCity={this.setStateAndCity}
                          removeClassCode={this.removeClassCode}
                          removeLocation={this.onrmvChild}
                          updateClassCodesInfo={this.updateClassCodesInfo}
                          updateLocationError={this.updateLocationError}
                          resetClassCodeDescriptionData={
                            this.resetClassCodeDescriptionData
                          }
                          effectiveDate={companyProfile.effectiveDate}
                          locationCount={locationCount}
                        />
                        <hr></hr>
                      </>
                    );
                  })}
                </div>
              </div>
              {/* <div className="mt-2">
                <button
                  type="button"
                  className="btnInvisible addLoc transpButton p-0"
                  onClick={this.onAddChildLoc}
                >
                  <img src={require("../../../images/plus4.png")} />{" "}
                  <b>Add new location</b>
                </button>
              </div> */}
            </div>
            <div className="emod-ques">
              {Object.values(emodStatesData).length > 0 && (
                <h4 className="emod-ques-heading">
                  <b>Experience Modification</b>
                </h4>
              )}
              {Object.keys(emodStatesData).map((e) => {
                return (
                  <EmodQuestions
                    key={e}
                    state={e}
                    emodData={emodStatesData[e]}
                    updateEmodInfo={this.updateEmodInfo}
                  />
                );
              })}
            </div>
          </form>
          <div className="row">
            <div className="col-12 text-center">
              <button
                disabled={(!otpVerified && !mailCampaignUserCheck) || viewOnly}
                type="button"
                onClick={() => this.submitCompPro()}
                id="profileSaveButton"
                className="btn btn-warning btn-lg btn-warning-shadow btnSubmits "
              >
                Continue
              </button>
              <p className="errMsg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  onAddChildLoc = (data) => {
    let { childrenLoc } = this.state;
    let dupChildrenLoc = childrenLoc;
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: {} },
      state: {},
      zipCode: {},
      cityName: {},
    };
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };

  onrmvChild = (addressId) => {
    let { childrenLoc, emodStatesData } = this.state;
    delete childrenLoc[addressId];

    let stateList = [];
    for (let addressBlockNo in childrenLoc) {
      if (childrenLoc[addressBlockNo].state.value) {
        stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
      }
    }
    if (stateList.length > 0) {
      let statesData = {
        ncciStates: [],
        actingNcciStates: [],
        indBureauStates: [],
      };
      axios
        .post(
          awsUrl2 + "/api/getExpModStateCategory",
          JSON.stringify({ states: stateList })
        )
        .then((response) => {
          statesData = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          let newEmodData = getEmodStatesData(statesData, emodStatesData);
          this.setState({ childrenLoc, emodStatesData: newEmodData });
        });
    } else {
      emodStatesData = {};
      this.setState({ childrenLoc, emodStatesData });
    }
  };
}

export default FormCompPro;
