import React from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import styles from "./QuotePageBanner.module.scss";
let calendarImage = require("../../../images/calendar.svg");
let clipboardImage = require("../../../images/clipboard.svg");
let constructionImage = require("../../../images/construction-and-tools.svg");
let humanImage = require("../../../images/human.svg");
let notesImage = require("../../../images/notes.svg");
let toDoImage = require("../../../images/to-do-list.svg");
let payrollImage = require("../../../images/banner-payroll.jpg");
let bmImage = require("../../../images/banner-ba.jpg");
let complianceImage = require("../../../images/banner-compliance.jpg");
let hrImage = require("../../../images/banner-hr.jpg");
let rmImage = require("../../../images/banner-rm.jpg");
let tAndAImage = require("../../../images/banner-t&a.jpg");
let firstSlideImage = require("../../../images/landing-image.jpg");

export default function QuotePageBanner() {
  const services = [
    {
      name: "Payroll & Tax Administration",
      content: (
        <ul>
          <h3>Payroll & Tax Administration</h3>
          <li>
            Secure cloud-based payroll & reporting portal with API Integration
          </li>
          <li>
            Single-stop shop for all payroll-related paperwork, such as W-2s &
            W-4s
          </li>
          <li>
            Payroll tax withholding & reporting, in adherence with all federal,
            state & local regulations
          </li>
          <li>
            Full-service from On-boarding to Off-boarding & reporting to federal
            government
          </li>
          <li>
            End-to-end payroll processing including payroll checks, direct
            deposits & related changes
          </li>
        </ul>
      ),
      icon: notesImage,
      image: payrollImage,
    },
    {
      name: "Human Resources",
      icon: humanImage,
      image: hrImage,
      content: (
        <ul>
          <h3>Human Resources</h3>
          <li>360-degree expertise & services as your outsourced HR partner</li>
          <li>All facets of HR, be it Strategic HR or Transactional HR</li>
          <li>
            Transactional HR services like payroll, workers’ comp & benefits
          </li>
          <li>
            Strategic HR services aligned with organizational goals -
            recruitment, culture, on-boarding
          </li>
        </ul>
      ),
    },
    {
      name: "Time & Attendance",
      icon: calendarImage,
      image: tAndAImage,
      content: (
        <ul>
          <h3>Time & Attendance</h3>
          <li>Simple, Efficient Timekeeping Technology</li>
          <li>Convenient online, automated, and mobile access</li>
          <li>
            Approve employees PTO & timecards, and submit timesheets directly
          </li>
          <li>
            Faster payroll processing, no paperwork, less unplanned overtime
          </li>
          <li>Increase employee productivity and accountability</li>
        </ul>
      ),
    },
    {
      name: "Compliance",
      icon: toDoImage,
      image: complianceImage,
      content: (
        <ul>
          <h3>Compliance</h3>
          <li>Minimize Time Consuming, Potentially Costly Penalties</li>
          <li>Administering preparation and submission of tax reports</li>
          <li>Preparation and submission of all compliance reports</li>
          <li>
            Administer all withholding and quarterly filings – W-2s, FICA &
            Unemployment Taxes
          </li>
        </ul>
      ),
    },
    {
      name: "Risk Management & Safety",
      icon: constructionImage,
      image: rmImage,
      content: (
        <ul>
          <h3>Risk Management & Safety</h3>
          <li>We help create a safe and productive work environment</li>
          <li>Affordable, fully-insured Workers’ Compensation</li>
          <li>Provide administrative assistance to injured workers</li>
          <li>Get access to rehabilitation and return-to-work programs</li>
          <li>Reduced claim frequency and severity</li>
        </ul>
      ),
    },
    {
      name: "Benefit Management",
      icon: clipboardImage,
      image: bmImage,
      content: (
        <ul>
          <h3>Benefit Management</h3>
          <li>
            Great employee benefits to attract & retain high-quality employees
          </li>
          <li>Best deals & Variety of options for your business</li>
          <li>
            Simplified HR paperwork from HIPAA compliance to benefits packages
          </li>
          <li>
            Stay up to date and in compliance with all federal and state
            regulations
          </li>
        </ul>
      ),
    },
  ];
  return (
    <Row noGutters className={styles.statsBannerContainer}>
      <Col className={styles.bannerColumn}>
        <h2>Solving your HR needs since 2000</h2>
        <div className={styles.statsBanner}>
          <div className={styles.statsBannerItem}>
            <div className={styles.statsBannerItemContent}>
              <h3>47</h3>
              <h5>States</h5>
            </div>
          </div>
          <div className={styles.statsBannerItem}>
            <div className={styles.statsBannerItemContent}>
              <h3>117</h3>
              <h5>
                Years of combined
                <br />
                leadership
              </h5>
            </div>
          </div>
          <div className={styles.statsBannerItem}>
            <div className={styles.statsBannerItemContent}>
              <h3>97%</h3>
              <h5>Retention</h5>
            </div>
          </div>
        </div>
      </Col>
      <Col className={styles.sliderColumn}>
        <Carousel
        // interval={null}
        >
          <Carousel.Item>
            <img
              className={styles.carouselImage}
              src={firstSlideImage}
              alt="First slide"
            />
            <Carousel.Caption
              className={`${styles.caption} ${styles.servicesListOuter}`}
            >
              <div className={styles.servicesList}>
                {services.map((service, index) => (
                  <div className={styles.servicesListItem}>
                    <div className={styles.serviceImageWrapper}>
                      <img src={service.icon} alt={service.name} />
                    </div>
                    <p>{service.name}</p>
                  </div>
                ))}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          {services.map((service, index) => (
            <Carousel.Item key={index}>
              <img
                className={styles.carouselImage}
                src={service.image}
                alt="First slide"
              />
              <Carousel.Caption className={`${styles.caption} pl-4 pr-4`}>
                {service.content}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
}
