// here we shall design the new progress bar and see how to resuse it
import React from "react";
const width = {
  one: "25%",
  two: "50%",
  three: "75%",
  four: "100%",
};

function NewProgress(props) {
  let stage = sessionStorage.getItem("formStage");
  if (!stage) {
    stage = "one";
  }
  return (
    <div className="color-bar">
      <span style={{ width: `${width[stage]}` }}></span>
    </div>
  );
}

export default NewProgress;
