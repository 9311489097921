import React, { Component } from "react";
import { changePassword } from "../../../utils/change_password";
import { validEmail, validPassword, clearErr } from "../../../utils/common";
require("bootstrap");
class FormChangePass extends Component {
  constructor() {
    super();
    this.state = {
      isSubmitted: false
    };
  }

  handleSubmit = () => {
    const valid = changePassword();
    const context = this;
    valid.then(function (value) {
      if (value) {
        context.setState({ isSubmitted: true });
      }
    });
  };

  render() {
    const { isSubmitted } = this.state;
    return (
      <div id="chngpwd" className="tab-pane fade text-center">
        <div id="formChange">
          <p
            id="sucMsg"
            className="text-center text-success"
            style={{ display: "none" }}
          >
            Password changed successfully!
          </p>

          {!isSubmitted && (
            <span>
              <p className="formTitle">CHANGE PASSWORD</p>
              <hr />
              <form id="changePassForm">
                <div>
                  <span className="errMsg" />
                  <input
                    autoComplete="off"
                    id="emailId"
                    type="text"
                    placeholder="Enter Email Id"
                    onBlur={validEmail}
                    onChange={clearErr}
                  />
                </div>
                <div>
                  <span className="errMsg" />
                  <input
                    autoComplete="off"
                    id="oldPass"
                    type="password"
                    placeholder="Old Password"
                    onBlur={validPassword}
                    onChange={clearErr}
                  />
                </div>
                <div>
                  <span className="errMsg" />
                  <input
                    autoComplete="off"
                    id="newPass"
                    type="password"
                    placeholder="New Password"
                    onBlur={validPassword}
                    onChange={clearErr}
                  />
                </div>
                <div>
                  <span className="errMsg" />
                  <input
                    autoComplete="off"
                    id="confPass"
                    type="password"
                    placeholder="Confirm Password"
                    onBlur={validPassword}
                    onChange={clearErr}
                  />
                </div>
                <span className="errMsg" />
                <button
                  type="button"
                  id="changeSubmit"
                  className="btnFormFinal"
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </button>
              </form>
            </span>
          )}
          <div className="formBottomLinks changeLater">
            <a
              data-toggle="tab"
              href="#lgin"
              aria-expanded="true"
              onClick={() => window.location.reload()}
            >
              I will change later
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default FormChangePass;
