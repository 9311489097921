// export const addCompanyName = companyName => ({ type: "Add_Company_Name", payload: companyName });
// export const addDescription = description => ({ type: "Add_Description", payload: description });
// export const addContactPerson = contactPerson => ({ type: "Add_Contact_Person", payload: contactPerson });
// export const addPhoneNumber = phoneNumber => ({ type: "Add_Phone_Number", payload: phoneNumber });
// export const addEmailAddress = emailAddress => ({ type: "Add_Email_Address", payload: emailAddress });
// export const addBusinessTypeId = businessTypeId => ({ type: "Add_Business_Type_Id", payload: businessTypeId });
// export const addFederalEmployeeId = federalEmployeeId => ({ type: "Add_Federal_Employee_Id", payload: federalEmployeeId });
// export const addEffectiveDate = effectiveDate => ({ type: "Add_Effective_Date", payload: effectiveDate });
// export const addExpirationDate = expirationDate => ({ type: "Add_Expiration_Date", payload: expirationDate });
// export const addLiabilityLimitId = liabilityLimitId => ({ type: "Add_Liability_LimitId", payload: liabilityLimitId });
// export const addProspectAddresses = prospectAddresses => ({ type: "Add_Prospect_Addresses", payload: prospectAddresses });
export const updateDetails = prospectUpdate => ({ type: "Update_Details", payload: prospectUpdate });
export const updateCarrier = carrierUpdate => ({type: "Update_Carrier", payload: carrierUpdate});                       