import React, { Component } from "react";
import styles from "./StatsBanner.module.scss";

class StatsBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={styles.statsBanner}>
        <div className={styles.statsBannerItem}>
          <div className={styles.statsBannerItemContent}>
            <h3>97%</h3>
            <h5>Retention</h5>
          </div>
        </div>
        <div className={styles.statsBannerItem}>
          <div className={styles.statsBannerItemContent}>
            <h3>117</h3>
            <h5>
              Years of combined
              <br />
              leadership
            </h5>
          </div>
        </div>
        <div className={styles.statsBannerItem}>
          <div className={styles.statsBannerItemContent}>
            <h3>47</h3>
            <h5>States</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsBanner;
