import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home2 from "./component/pages/Home2";
import ProgramDetails from "./component/pages/ProgramDetails";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import SolveDashboardSales from "./component/pages/SolveDashboardSales";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";

class App extends Component {
  componentDidMount() {
    this.setTitle();
  }
  setTitle = () => {
    process.env.REACT_APP_MODE == "beta"
      ? (document.title = "PEO2 - solving your HR needs")
      : (document.title = "SolveHR - solving your HR needs ");
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/demo" component={DemoRouteHandler} />
          <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_ADMIN_GROUP}
            exact
            path="/dashboard"
            component={Tabs}
          />
          <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_SALES_GROUP}
            exact
            path="/SalesDashboard"
            component={SolveDashboardSales}
          />
          <Route exact path="/Quote" component={NewQuote} />
          <AntiPrivateRoute path="/Login" component={Login} />
          <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_CARRIER_GROUP}
            exact
            path="/PanelUW"
            component={PanelUW}
          />
          <Route path="/program-details" component={ProgramDetails} />
          <Route path="/" component={Home2} />
        </Switch>
      </Router>
    );
  }
}

export default App;
