import $ from 'jquery';
import { Auth } from 'aws-amplify';
import { deleteAllCookies } from './common';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

export async function changePassword() {
  var username = $('#emailId').val();
  var oldPass = $('#oldPass').val();
  var newPass = $('#newPass').val();
  var confPass = $('#confPass').val();
  let validForm = validation();
  let submitted = false;
  console.log("validation form:", validForm);
  if (confPass !== newPass || newPass === "") {
    $("#confPass").css('border-color', 'red');
    $("#confPass").siblings('.errMsg').html("<br /> The passwords don't match");
    $("#confPass").focus();
  }
  if (validForm) {
    try {
      $('#loader').css('display', 'block');
      const signinUser = await Auth.signIn(username.toLowerCase(), oldPass);
      if (signinUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(signinUser, newPass).then(async user => {
          console.log("respo1", user);
          deleteAllCookies();
          sessionStorage.clear();
          localStorage.clear();
          $("#sucMsg").css('display', 'block');
          $('#emailId').val('');
          $('#oldPass').val('');
          $('#newPass').val('');
          $('#confPass').val('');
          $('.changeLater').children().text("Login");
          $('.changeLater').children().css({ "color": "white", "width": "100%" });
          $(".changeLater").css({
            display: "flex",
            width: "100%",
            background: "#095d98",
            border: "none",
            "border-radius": "4px",
            "box-shadow": "3px 3px 5px #555555"
          });
          submitted = true;
          $('#loader').css('display', 'none');
        }).catch(error => {
          console.log("Error while setting new password", error);
          $('#loader').css('display', 'none');
          $('#changeSubmit').siblings('.errMsg').html('<br /> ' + error.message);
        })
      }
      else {
        await Auth.currentAuthenticatedUser().then(async user => {
          await Auth.changePassword(user, oldPass, newPass).then(res => {
            console.log("respo", res);
            deleteAllCookies();
            sessionStorage.clear();
            localStorage.clear();
            $("#sucMsg").css('display', 'block');
            $('#emailId').val('');
            $('#oldPass').val('');
            $('#newPass').val('');
            $('#confPass').val('');
            $('.changeLater').children().text("Login");
            $('.changeLater').children().css({ "color": "white", "width": "100%" });
            $(".changeLater").css({
              display: "flex",
              width: "100%",
              background: "#095d98",
              border: "none",
              "border-radius": "4px",
              "box-shadow": "3px 3px 5px #555555"
            });
            submitted = true;
            $('#loader').css('display', 'none');
          }).catch(error => {
            $('#loader').css('display', 'none');
            $('#changeSubmit').siblings('.errMsg').html('<br /> ' + error.message);
          });
        }).catch(error => {
          console.log("Error while getting current authenticated user", error);
          $('#loader').css('display', 'none');
          $('#changeSubmit').siblings('.errMsg').html('<br /> ' + error);

        });
      }
      await Auth.signOut();
    } catch (err) {
      console.log("--------------", err);
      $('#loader').css('display', 'none');
      $('#changeSubmit').siblings('.errMsg').html('<br /> ' + err.message);
    }
  }
  return submitted;
}

function validation() {
  let valid = true;
  console.log("comming", $('#emailId').val());
  if ($('#emailId').val() === '' || $('#emailId').css('border-left-color') === 'rgb(255, 0, 0)') {
    $('#emailId').css('border-color', 'red');
    $('#emailId').siblings('.errMsg').html(' Email is required.');
    $('#emailId').focus();
    valid = false;
  }
  if ($('#oldPass').val() === '' || $("#oldPass").css('border-left-color') === 'rgb(255, 0, 0)') {
    $('#oldPass').css('border-color', 'red');
    $('#oldPass').siblings('.errMsg').html(' Old Password is required.');
    $('#oldPass').focus();
    valid = false;
  }
  if ($('#newPass').val() === '' || $("#newPass").css('border-left-color') === 'rgb(255, 0, 0)') {
    $('#newPass').css('border-color', 'red');
    $('#newPass').siblings('.errMsg').html(' New Password is required.');
    $('#newPass').focus();
    valid = false;
  }
  if ($('#confPass').val() === '' || $('#confPass').val() !== $('#newPass').val() || $('#confPass').css('border-left-color') === 'rgb(255, 0, 0)') {
    $('#confPass').css('border-color', 'red');
    $('#confPass').siblings('.errMsg').html(' Confirm Password is required.');
    $('#confPass').focus();
    valid = false;
  }
  console.log("---", valid);

  return valid;
}