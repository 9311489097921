import store from "./store/index";
import { updateDetails, updateCarrier } from "./actions/index";
// import { addDescription } from "./actions/index";
// import { addContactPerson } from "./actions/index";
// import { addPhoneNumber } from "./actions/index";
// import { addEmailAddress } from "./actions/index";
// import { addBusinessTypeId } from "./actions/index";
// import { addFederalEmployeeId } from "./actions/index";
// import { addEffectiveDate } from "./actions/index";
// import { addExpirationDate } from "./actions/index";
// import { addLiabilityLimitId } from "./actions/index";
// import { addProspectAddresses } from "./actions/index";


window.store = store;
window.updateDetails = updateDetails;
window.updateCarrier = updateCarrier;
// window.addCompanyName = addCompanyName;
// window.addDescription = addDescription;
// window.addContactPerson = addContactPerson;
// window.addPhoneNumber = addPhoneNumber;
// window.addEmailAddress = addEmailAddress;
// window.addBusinessTypeId = addBusinessTypeId;
// window.addFederalEmployeeId = addFederalEmployeeId;
// window.addEffectiveDate = addEffectiveDate;
// window.addExpirationDate = addExpirationDate;
// window.addLiabilityLimitId = addLiabilityLimitId;
// window.addProspectAddresses = addProspectAddresses;