import React from "react";
import DashboardSolve from "./DashboardSolve";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { logout } from "../../utils/common";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected } = this.state;
    const tabsMap = {
      tab1: "Engaged with email campaign",
      tab2: "Did not engage with email campaign",
      tab3: "Sales Person Details",
      tab4: "Not Eligible",
    };

    return (
      <div className="dashboard-solve">
        <div
          className="d-flex bg-light"
          style={{ borderBottomStyle: "groove" }}
        >
          <Header />
          <div
            onClick={logout}
            className="d-flex align-items-center logout-div"
          >
            Logout
          </div>
        </div>
        <div className="tabs p-4">
          <div className="d-flex justify-content-center mb-3">
            <h2>
              <b>Prospect Dashboard</b>
            </h2>
          </div>
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px" }}
          >
            {Object.keys(tabsMap).map((tab) => (
              <Nav.Item>
                <Nav.Link eventKey={tab} onClick={() => this.setSelected(tab)}>
                  {tabsMap[tab]}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab}>
              {<DashboardSolve tabName={tab} selected={selected} />}
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

export default Tabs;
