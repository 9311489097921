/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_GBP3IhlBm",
    "aws_user_pools_web_client_id": "75n4dcea86hqq6o1mepl8o0hn8",
    "oauth": {}
};


export default awsmobile;
