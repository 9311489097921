import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dropworks from "./Dropworks";
import DropUserProfile from "./DropUserProfile";
import Dropresources from "./Dropresources";
import QuoteButtons from "./QuoteButtons";
import { openNav, closeNav } from "../../utils/common";
import { Auth } from "aws-amplify";
import UserIcon from "../../images/user-circle-regular.svg";
import { logout } from "../../utils/common";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: ["user"],
      userName: "",
      displayProfileMenu: false,
      displayQuotesMenu: false,
      displayQuoteButton: false,
      displayLoginButton: true,
      displayHowItWorks: false,
      displayResources: false,
      displayUnderWriterLink: false,
      displayComplianceLink: false,
      displayDashboard: false,
    };
  }

  componentDidMount() {
    let userRole = ["user"];

    var curre = Auth.currentSession()
      .then((res) => {
        console.log("Current Authenticated Response User: ", res);
        userRole = res.accessToken.payload["cognito:groups"];
        let userName = res.accessToken.payload.username.split("@")[0];
        this.setState({
          userRole: userRole,
          userName: userName,
        });
        console.log("Role: ", this.state.userRole);
        if (userName !== "" && userRole.length > 0) {
          // This is a user who has logged in.
          this.setState({
            displayLoginButton: false,
            displayProfileMenu: true,
            displayQuoteButton: false,
          });
          sessionStorage.setItem("isLoggedIn", true);
          if (this.state.userRole.includes("User")) {
            this.setState({
              displayQuotesMenu: true,
              displayDashboard: true,
            });
          } else if (this.state.userRole.includes("Carrier")) {
            this.setState({
              displayHowItWorks: false,
              displayResources: false,
              displayUnderWriterLink: true,
              displayDashboard: true,
            });
          } else if (
            this.state.userRole.includes("Compliance") ||
            this.state.userRole.includes("ComplianceAdmin")
          ) {
            this.setState({
              displayHowItWorks: false,
              displayResources: false,
              displayComplianceLink: true,
            });
          }
        } else {
          // This user could not log in.
          // No change in state required.
          console.log("User could not log in.");
          this.setState({
            displayHowItWorks: true,
            displayResources: true,
          });
        }
      })
      .catch((err) => {
        if (err === "No current user") {
          console.log("Current Authenticated User: ", err);
        }
        this.setState({
          displayHowItWorks: true,
          displayResources: true,
        });
      });
    console.log("Current Authenticated User: ", curre);
  }

  render() {
    const pathLogin = window.location.pathname === "/Login";
    const variant = this.props.variant;
    return (
      <nav id="navbarMain" className="new-navbar container-fluid">
        <div className="row h-100 justify-content-between">
          <Link
            to={"/"}
            className={
              variant === "large-logo"
                ? "navbar-brand d-flex align-items-center"
                : "navbar-brand pride-logo d-flex align-items-center"
            }
          >
            <img
              className={
                variant === "large-logo" ? "new-logo boxed-logo" : "new-logo"
              }
              src={require("../../images/pride_logo.jpg")}
              id="firstLogo"
              alt="Pride Logo"
            />
          </Link>
          <Link
            to={"/"}
            className={
              variant === "large-logo"
                ? "navbar-brand d-flex align-items-center"
                : "navbar-brand insurecomp-logo d-flex align-items-center"
            }
          >
            <img
              className={
                variant === "large-logo" ? "new-logo boxed-logo" : "new-logo"
              }
              src={
                variant === "large-logo"
                  ? require("../../images/new-logo.png")
                  : require("../../images/logo-white.png")
              }
              id="secondLogo"
              alt="InsureComp Logo"
            />
          </Link>
        </div>
      </nav>
    );
  }
}

export default Navbar;
