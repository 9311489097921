import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import ImageBlock from "../subcompo/sections-home/ImageBlock";
import LandingFooter from "../subcompo/sections-home/LandingFooter";
import StatsBanner from "../subcompo/sections-home/StatsBanner";
import Testimonials from "../subcompo/sections-home/Testimonials";
import Services from "../subcompo/sections-home/Services";
import RequestCallBack from "../subcompo/sections-home/RequestCallBack";
import LargeCTA from "../subcompo/sections-home/LargeCTA";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import { Auth } from "aws-amplify";
import { logout } from "../../utils/common";
import styles from "./Home2.module.scss";
import { Container } from "react-bootstrap";
const numeral = require("numeral");
let landingImage = require("../../images/landing-image.jpg");

class Home2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailCampaignUserCheck: false,
      loadingData: false,
    };
  }

  async componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
    let isLoggedIn;
    try {
      isLoggedIn = await Auth.currentAuthenticatedUser();
      let groups =
        isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
      if (isLoggedIn) {
        this.setState({ isLoggedIn: true, groups });
      }
    } catch (error) {}
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let campaignName = urlParams.get("campaignName");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain;
    let viewButton = urlParams.get("viewButton");
    if (user) {
      user = user.toLowerCase();
      this.setState({ loadingData: true });
      let tempBusinessName = user.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = user.split("@")[0];
      } else {
        domain = business_name;
      }
      let currDomain = sessionStorage.getItem("domain");
      let timestamp = Math.floor(Date.now()).toString();
      sessionStorage.setItem("visitTimestamp", timestamp);
      sessionStorage.setItem("landingEmail", user);
      this.setState({ mailCampaignUserCheck: true });
      axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          let requestBody = {};
          if (response.data) {
            sessionStorage.setItem("emailCampaignUser", true);
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  emailId: { value: lineObj.user_email_id },
                  fein: { value: lineObj.fein },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };
                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let indicationCost = lineObj.carrier_location_data.total;

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem("domain", domain);
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
                requestBody = {
                  email: user.toLowerCase(),
                  domain: domain.toLowerCase(),
                  timestamp: timestamp,
                  payrollData: childrenLoc,
                  companyProfile,
                  indicationCost,
                  emodStatesData,
                  campaignName,
                  preventCompanyProfileUpdate: true,
                };

                if (user && !viewButton) {
                  axios
                    .post(
                      awsUrl2 + "/api/updateSolveTrackingData",
                      JSON.stringify(requestBody)
                    )
                    .then((res) => {
                      axios
                        .post(
                          awsUrl2 +
                            "/api/updateEngagedWithEmailCampaign/" +
                            domain,
                          JSON.stringify(domain)
                        )
                        .finally(() => {
                          this.setState({ loadingData: false });
                        });
                    })
                    .catch((err) => {
                      this.setState({ loadingData: false });
                    });
                } else {
                  this.setState({ loadingData: false });
                }
              }
            }
          } else {
            let domainResp = await axios.get(
              awsUrl2 + "/api/getDomain/" + user
            );
            if (domainResp.data && domainResp.data.domain) {
              domain = domainResp.data.domain;
            }
            if (currDomain !== domain) {
              sessionStorage.setItem("domain", domain);
              axios
                .get(awsUrl2 + "/api/getDomainInputData/" + domain)
                .then((response) => {
                  if (response.status === 200 && response.data.email_specific) {
                    sessionStorage.setItem("emailCampaignUser", true);
                    let {
                      companyProfile,
                      childrenLoc,
                      emodStatesData,
                      indicationCost,
                      email_specific,
                    } = response.data;

                    if (email_specific) {
                      if (user in email_specific) {
                        companyProfile = email_specific[user].companyProfile;
                      } else {
                        companyProfile.emailId.value = user;
                      }
                    }

                    let currProspect = {
                      childrenLoc,
                      companyProfile,
                      emodStatesData,
                    };

                    if (!("healthQues" in companyProfile)) {
                      companyProfile.healthQues = { value: true };
                    }

                    sessionStorage.setItem(
                      "currProspect",
                      JSON.stringify(currProspect)
                    );
                    sessionStorage.setItem("formStage", "one");

                    requestBody = {
                      email: user.toLowerCase(),
                      domain: domain.toLowerCase(),
                      timestamp: timestamp,
                      payrollData: childrenLoc,
                      companyProfile,
                      indicationCost,
                      emodStatesData,
                      campaignName,
                      emailSpecific: email_specific,
                    };

                    if (user && !viewButton) {
                      axios
                        .post(
                          awsUrl2 + "/api/updateSolveTrackingData",
                          JSON.stringify(requestBody)
                        )
                        .then((res) => {
                          axios
                            .post(
                              awsUrl2 +
                                "/api/updateEngagedWithEmailCampaign/" +
                                domain,
                              JSON.stringify(domain)
                            )
                            .finally(() => {
                              this.setState({ loadingData: false });
                            });
                        })
                        .catch((err) => {
                          this.setState({ loadingData: false });
                        });
                    } else {
                      this.setState({ loadingData: false });
                    }
                  }
                })
                .catch((err) => {
                  this.setState({ loadingData: false });
                });
            } else {
              this.setState({ loadingData: false });
            }
          }
        })
        .catch((err) => {
          this.setState({ loadingData: false });
        });
    } else {
      this.setState({ loadingData: false });
    }
  }

  handleLogout = () => {
    logout();
    this.setState({ isLoggedIn: false });
  };

  render() {
    let { isLoggedIn, loadingData, groups } = this.state;
    return (
      <>
        <Header />
        <div className="home2 align-items-center">
          <ImageBlock
            heading="Looking for HR Solutions for your business?"
            image={landingImage}
          />
          <div className={styles.contentSection}>
            <div className="px-4">
              <h2 className="text-center">
                WE MAKE IT EASY, SO YOU CAN FOCUS ON YOUR BUSINESS & YOUR
                CLIENTS.
              </h2>
              <div className={`${styles.content} d-flex mt-4 pl-3`}>
                <div className={styles.info}>
                  <p>
                    Functioning as a full-service outsourced HR department, we
                    are a single source for multiple solutions aimed at
                    providing administrative relief, Fortune 500 benefits,
                    reduced liabilities, improved productivity and increased
                    profitability to our clients.
                  </p>
                  <p>
                    {`
                 ${
                   process.env.REACT_APP_MODE == "beta"
                     ? " PEO2 "
                     : "SolveHR’s Human Resource "
                 } services are designed to maximize
                    employee productivity, improve employee relations and reduce
                    employer risks associated with personnel administration. Our
                    team is trained to help your organization comply with the
                    multitude of federal and state regulatory and reporting
                    requirements.  ${
                      process.env.REACT_APP_MODE == "beta"
                        ? " PEO2 "
                        : "SolveHR  "
                    } can assist with employee handbooks and
                    provide online training sessions for supervisors and
                    employees.`}
                  </p>
                  <p className="mb-0">
                    {`
                    As the worksite employer, your organization retains complete
                    control of its ability to direct the daily work performance
                    of your employees while  ${
                      process.env.REACT_APP_MODE == "beta"
                        ? " PEO2 "
                        : "SolveHR  "
                    } is by your side to guide and
                    coach you to make the best decisions for you and your
                    organization.`}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.statsbanner}>
              <StatsBanner />
            </div>
          </div>
          {!checkMailCampaignUser() && (
            <div className={styles.indicationButton}>
              <Link to="/Quote">
                <LargeCTA disabled={loadingData} />
              </Link>
            </div>
          )}
          <Services />
          <RequestCallBack />
          <Testimonials />
          <LandingFooter
            isLoggedIn={isLoggedIn}
            groups={groups}
            handleLogout={this.handleLogout}
          />
        </div>
      </>
    );
  }
}

export default Home2;
