import React, { Component } from 'react';
import { logout } from '../../utils/common';

class DropUserProfile extends Component {
    constructor(props){
        super(props);
    }
  render() {

    return (
        <div id='profDrop' className='container-fluid'>
            <ul className='navDrop'>
                <li className='linkLike'>Welcome {this.props.username}</li> <hr />
                <li className='linkLike' onClick={logout}> Logout</li>
            </ul>
        </div>
    );
  }
}

export default DropUserProfile
