import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import styles from "./Header.module.scss";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";

let solveInsurecompLogo = require("../../images/Solve-HR-Logo.png");

let icomplogo = require("../../images/new-logo.png");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Navbar expand="lg" className={`w-100 bg-light ${styles.navBar}`}>
        <Navbar.Brand href="/">
          <img
            className={styles.solveLogo}
            src={
              process.env.REACT_APP_MODE == "beta"
                ? icomplogo
                : solveInsurecompLogo
            }
            alt="solve-insurecomp logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link
              href="/"
              className={
                window.location.pathname.toLowerCase() === "/"
                  ? "font-weight-bold mr-4 text-primary"
                  : ""
              }
            >
              About Us
            </Nav.Link>
            {["/"].includes(window.location.pathname) && (
              <Form inline>
                <Button
                  variant="warning"
                  className={styles.headerButton}
                  href="/Quote"
                >
                  {checkMailCampaignUser()
                    ? "Go back to my quote"
                    : "GET FREE QUOTE"}
                </Button>
              </Form>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
