let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}

console.log("env", process.env);

var localURL = "http://localhost:3000";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://7plunrdvy4.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://vg20x2xgie.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://v0925wuove.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://0pyrd74ou2.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://2znrarm65h.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://7nwe2wybd6.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";

if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "3.5.6 (m)";
