import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return <div id="footerMain" className="container-fluid"></div>;
  }
}

export default Footer;
