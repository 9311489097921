import $ from 'jquery';
import { tempURL, awsUrl } from '../config';
import axios from 'axios';
import { showErrAlert, getHeader } from './common';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

export function otherAddress(e) {
    let selVal = $(e.target).val();
    if (selVal === 'o') {
        $('#newAddress').css('display', 'block');
    } else {
        $('#newAddress').css('display', 'none');
    }
}

export async function submitPayment(tAndC) {
    if (validation(tAndC)) {
        $('#loader').css('display', 'block');
        if (sessionStorage.getItem("require") !== undefined && sessionStorage.getItem("require") !== null && sessionStorage.getItem("currProspect") !== null && sessionStorage.getItem("currProspect") !== undefined) {
            let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;

            let requireData = sessionStorage.getItem("require").split("!!");
            let idList = sessionStorage.getItem("require").split("+");
            let carrierList = [];

            for (let id of idList.slice(0, -1)) {
                let carrier = `carrier_${id.split("_").reverse()[0]}`;
                if (!carrierList.includes(carrier)) {
                    carrierList.push(carrier);
                }
            }
            let email_address = {};
            let setQuoteStatus = [];

            if ($("#mailAdd").val() === 'o') {
                email_address.streetAddress1 = $("#streetAddress1O").val();
                email_address.streetAddress12 = $("#streetAddress2O").val();
                email_address.cityName = $("#cityNameO").val();
                email_address.stateCode = $("#stateCodeO").val();
                email_address.zipCode = $("#zipCodeO").val();

            } else {
                let inputAddres = $("#mailAdd").val().split(",  ");
                console.log("Input addres", inputAddres);
                email_address.streetAddress1 = inputAddres[0];
                email_address.streetAddress2 = inputAddres[1];
                email_address.cityName = inputAddres[2];
                email_address.stateCode = inputAddres[3];
                email_address.zipCode = inputAddres[4];
            }

            let sign_name = $("#authSign").val();
            let nineDigits = Math.floor(100000000 + Math.random() * 900000000);
            let twoDigit = Math.floor(10 + Math.random() * 90);
            let policyNumber = 'ECP-' + nineDigits + "-" + twoDigit;
            let newDate = new Date()
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let dayDate = date + "/" + month + "/" + year;

            for (let uuid of requireData) {
                setQuoteStatus.push({
                    email: email,
                    uuid: uuid,
                    sign_name: sign_name,
                    mail_address: email_address,
                    quote_status: "bound",
                    policy_number: policyNumber,
                    bound_date: dayDate
                })
            }

            const header = await getHeader();
            axios.post(awsUrl + "/api/setQuoteStatus", JSON.stringify(setQuoteStatus), header).then(res => {
                console.log('resPonse after update: ', res);
                let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
                let tableName = "ProspectTable";
                currProspect.formStage = 'six';
                currProspect.require = sessionStorage.getItem('require');
                axios.post(awsUrl + "/api/postTablesData/" + tableName, JSON.stringify(currProspect), header).then(res => {
                    console.log("Success response after posting prospect data: ", res);
                    $('#loader').css('display', 'none');
                    sessionStorage.setItem('formStage', 'six');
                    window.location.reload();
                }).catch(err => {
                    $('#loader').css('display', 'none');
                    console.log("error in propspect post", err);
                    showErrAlert(err);
                });

            }).catch(err => {
                $('#loader').css('display', 'none');
                console.log("error in post", err);
                showErrAlert(err);
            });

        }


    }
}

function validation(tAndC) {
    let flage = true;
    if ($("#authSign").val().trim() === '') {
        $("#authSign").css("border-color", 'red');
        $("#authSign").focus();
        flage = false;
    }

    if ($("#mailAdd").val() === 'o') {
        if ($("#streetAddress1O").val().trim() === '') {
            $("#streetAddress1O").css('border-color', 'red');
            $("#streetAddress1O").focus();
            flage = false;
        }
        // if ($("#streetAddress2O").val() === '') {
        //     $("#streetAddress2O").css('border-color', 'red');
        //     $("#streetAddress2O").focus();
        //     flage = false;
        // }
        if ($("#zipCodeO").val().trim() === '') {
            $("#zipCodeO").css('border-color', 'red');
            $("#zipCodeO").focus();
            flage = false;
        }
        if ($("#stateCodeO").val().trim() === '') {
            $("#stateCodeO").css('border-color', 'red');
            $("#stateCodeO").focus();
            flage = false;
        }
        if ($("#cityNameO").val().trim() === '') {
            $("#cityNameO").css('border-color', 'red');
            $("#cityNameO").focus();
            flage = false;
        }

    } else {
        if ($("#mailAdd").val() === '') {
            $("#mailAdd").css('border-color', 'red');
            $("#mailAdd").focus();
            flage = false;
        }
    }

    if (!tAndC) {
        flage = false;
    }
    return flage;
}