import React, { Component } from "react";
import { Link } from "react-router-dom";

class Dropworks extends Component {
  render() {
    return (
      <div id="workDrop">
        <ul className="navDrop">
          <Link id="HowItWorksRates" to={"/how-it-works/brochure"}>
            <li className="linkLike">
              <img src={require("../../images/icon_list_1.png")} alt="" />{" "}
              Brochure
            </li>
          </Link>
          <Link id="HowItWorksRates" to={"/how-it-works/rates"}>
            <li className="linkLike">
              <img src={require("../../images/icon_list_1.png")} alt="" /> Find
              Rate
            </li>
          </Link>
          <Link id="HowItWorksQuotes" to={"/how-it-works/quotes"}>
            <li className="linkLike">
              <img src={require("../../images/icon_list_2.png")} alt="" /> Get
              Quote
            </li>
          </Link>
          <Link id="HowItWorksBind" to={"/how-it-works/bind"}>
            <li className="linkLike">
              <img src={require("../../images/icon_list_3.png")} alt="" /> Bind
            </li>
          </Link>
        </ul>
      </div>
    );
  }
}

export default Dropworks;
