import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./ImageBlock.module.scss";

export default function ImageBlock(props) {
  return (
    <Row className={styles.imageBlock + " no-gutters"}>
      <Col sm={6} className={styles.headingColumn}>
        <h1>{props.heading}</h1>
        {props.subtext && <p>{props.subtext}</p>}
      </Col>
      <Col sm={6} className={styles.imageColumn}>
        <img src={props.image} alt="landing" />
      </Col>
    </Row>
  );
}
