// export const Add_Company_Name = "Add_Company_Name";
// export const Add_Description = "Add_Description";
// export const Add_Contact_Person = "Add_Contact_Person";
// export const Add_Phone_Number = "Add_Phone_Number";
// export const Add_Email_Address = "Add_Email_Address";
// export const Add_Business_Type_Id = "Add_Business_Type_Id";
// export const Add_Federal_Employee_Id = "Add_Federal_Employee_Id";
// export const Add_Effective_Date = "Add_Effective_Date";
// export const Add_Expiration_Date = "Add_Expiration_Date";
// export const Add_Liability_LimitId = "Add_Liability_LimitId";
// export const Add_Prospect_Addresses = "Add_Prospect_Addresses";
export const Update_Details = "Update_Details";
export const Update_Carrier = "Update_Carrier";