import { Update_Details, Add_Company_Name,Add_Description,Add_Contact_Person,Add_Phone_Number,Add_Email_Address,Add_Business_Type_Id,Add_Federal_Employee_Id,Add_Effective_Date,Add_Expiration_Date,Add_Liability_LimitId,Add_Prospect_Addresses, Update_Carrier } from "../constants/action-types";


const initialState = {
      prospectDetails:{
            companyName:'',
            description:'',
            contactPerson:'',
            phoneNumber:'',
            emailAddress:'',
            businessTypeId:'',
            federalEmployeeId:'',
            effectiveDate:'',
            expirationDate:'',
            liabilityLimitId:'',
            prospectAddresses:'',
            prospectId:''
      },
      carrierDetails:''

};
const rootReducer = (state = initialState, action) => {
     
  switch (action.type) {
      case Update_Details:
      state.prospectDetails=(action.payload);
      return state;

      case Update_Carrier:
      state.carrierDetails = (action.payload);
      return state;
	
	// case Add_Description:
      // state.insureCompany.description=(action.payload);
      // return state;
	  
	// case Add_Contact_Person:
      // state.insureCompany.contactPerson=(action.payload);
      // return state;
	
	// case Add_Phone_Number:
      // state.insureCompany.phoneNumber=(action.payload);
      // return state;
	
	// case Add_Email_Address:
      // state.insureCompany.emailAddress=(action.payload);
      // return state;
	  
	// case Add_Business_Type_Id:
      // state.insureCompany.businessTypeId=(action.payload);
      // return state;
	  
	// case Add_Federal_Employee_Id:
      // state.insureCompany.federalEmployeeId=(action.payload);
      // return state;
	
	// case Add_Effective_Date:
      // state.insureCompany.effectiveDate=(action.payload);
      // return state;
	  
	// case Add_Expiration_Date:
      // state.insureCompany.expirationDate=(action.payload);
      // return state;
	  
	// case Add_Liability_LimitId:
      // state.insureCompany.liabilityLimitId=(action.payload);
      // return state;
	
	// case Add_Prospect_Addresses:
      // state.insureCompany.prospectAddresses=(action.payload);
      // return state;
	
    default:
      return state;
  }
};
export default rootReducer;