import React from "react";
import styles from "./LargeCTA.module.scss";

export default function LargeCTA(props) {
  return (
    <button
      className={styles.button + " btn btn-warning btn-lg"}
      disabled={props.disabled}
    >
      GET FREE QUOTE
    </button>
  );
}
