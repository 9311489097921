import React, { Component } from "react";
import { clearErr } from "../../utils/common";
var stateCity = require("../../utils/state_city.json");

class StateList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   const { updateLocationInfo } = this.props;
  //   let formStage = sessionStorage.getItem("formStage");
  //   if (formStage === undefined || formStage === null) {
  //     updateLocationInfo("TX", 0, "state");
  //   }
  // }

  componentDidUpdate() {
    const { resetClassCodeDescriptionData, addressBlockNo } = this.props;
    if (this.state.locationInfo !== this.props.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
    if (this.props.state !== this.state.state) {
      this.setState({
        state: this.props.state,
      });
      // resetClassCodeDescriptionData(addressBlockNo);
    }
  }

  render() {
    const { updateLocationInfo, resetClassCodeDescriptionData } = this.props;
    const { locationInfo } = this.state;
    const number = locationInfo ? locationInfo.number : null;

    const stateList = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item in stateCity) {
      if (locationInfo && item === locationInfo.state.value) {
        stateList.push(
          <option key={item} value={item} selected>
            {item}
          </option>
        );
      } else {
        stateList.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
    }
    return (
      <select
        name="stateCodes"
        onChange={(event) => {
          updateLocationInfo(event.target.value, number, "state");
          resetClassCodeDescriptionData(number);
        }}
        style={
          locationInfo && locationInfo.state.error ? { borderColor: "red" } : {}
        }
      >
        {stateList}
      </select>
    );
  }
}
export default StateList;
