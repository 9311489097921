import React, { Component } from "react";
let dollarImage = require("../../../images/dollar-symbol.svg");
let stocks = require("../../../images/money-graph-with-up-arrow.svg");

class Stats1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { text2 } = this.props;
    return (
      <div className="stat-2-1 d-flex align-items-center">
        {text2 ? (
          <span className="text-left">
            Bringing you Technology-Powered PEO Solutions!
          </span>
        ) : (
          <>
            <span className="text-center">Boost Business Growth 7 - 9%</span>
            <img src={stocks} alt="dollar-icon" />
          </>
        )}
      </div>
    );
  }
}

export default Stats1;
