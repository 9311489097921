import React from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import ImageBlock from "../subcompo/sections-home/ImageBlock";
import StatsBanner from "../subcompo/sections-home/StatsBanner";
import Testimonials from "../subcompo/sections-home/Testimonials";
import RequestCallBack from "../subcompo/sections-home/RequestCallBack";
import LandingFooter from "../subcompo/sections-home/LandingFooter";
import LargeCTA from "../subcompo/sections-home/LargeCTA";
import styles from "./Home2.module.scss";
import { Container } from "react-bootstrap";
import Home2 from "./Home2";
let landingImage = require("../../images/home-solve-office.jpg");

class ProgramDetails extends Home2 {
  render() {
    let { isLoggedIn, loadingData, groups } = this.state;
    return (
      <>
        <Header />
        <div className="home2 align-items-center">
          <ImageBlock
            // heading="Program Details"
            image={landingImage}
            subtext="Functioning as a full-service outsourced HR department, we are a
            single source for multiple solutions aimed at providing
            administrative relief, Fortune 500 benefits, reduced
            liabilities, improved productivity and increased profitability
            to our clients. As the worksite employer, your organization
            retains complete control of its ability to direct the daily work
            performance of your employees while SolveHR is by your side to
            guide and coach you to make the best decisions for you and your
            organization"
          />
          <div className="text-center mb-5 mt-5">
            <Link to="/Quote">
              <LargeCTA disabled={loadingData}>Get Free Indication</LargeCTA>
            </Link>
          </div>
          <RequestCallBack />
          <Testimonials />
          <StatsBanner />
          <LandingFooter
            isLoggedIn={isLoggedIn}
            groups={groups}
            handleLogout={this.handleLogout}
          />
        </div>
      </>
    );
  }
}

export default ProgramDetails;
