import React, { Component } from "react";
import numeral from "numeral";

class QuoteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let quoteFactors = sessionStorage.getItem("quoteFactors");
    if (quoteFactors) {
      quoteFactors = JSON.parse(quoteFactors);
    }
    this.setState({ quoteFactors });
  }

  render() {
    let { quoteFactors } = this.state;
    let showQuote =
      quoteFactors && quoteFactors.total && quoteFactors.total !== "0"
        ? true
        : false;
    return (
      <div>
        <h3>
          <b>Here's your Pricing Indication</b>
        </h3>
        <div className="mt-5" style={{ fontSize: "17px" }}>
          {showQuote && (
            <>
              <div className="flex-column">
                <div className="mb-3 d-flex align-items-baseline">
                  <span className="mr-2">
                    {"Admin Fee (per Employee per Month): "}
                  </span>
                  <b>{numeral(quoteFactors.adminFee).format("$0,0")}</b>
                </div>
                <div className="row mb-3 d-flex align-items-baseline">
                  <span className="col">
                    {"Cost for Worker's Compensation and Payroll Services "}
                  </span>
                </div>
                <table className="ml-4">
                  <tbody>
                    <tr>
                      <td className="text-right pr-3">WC:</td>
                      <td>
                        <b>
                          {Math.round(
                            numeral(quoteFactors.teap)
                              .divide(quoteFactors.payroll)
                              .multiply(10000)
                              .value()
                          ) / 100}
                          % *
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right pr-3">FICA:</td>
                      <td>
                        <b>{numeral(quoteFactors.ficaCharge).value()}%</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right pr-3">FUTA:</td>
                      <td>
                        <b>{numeral(quoteFactors.futaCharge).value()}%</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right pr-3">SUTA:</td>
                      <td>
                        <b>{numeral(quoteFactors.sutaCharge).value()}%</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
          {!showQuote && "Sorry, no carriers are available for you."}
        </div>
      </div>
    );
  }
}

export default QuoteTable;
