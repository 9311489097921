import React from "react";

class Tab extends React.Component {
  constructor(props) {
    super(props);
  }

  handleTabClick = () => {
    const { tabName, handleTabClick } = this.props;
    handleTabClick(tabName);
  };

  render() {
    let { handleTabClick, activeTab, tabName } = this.props;
    let className = "tab-list-item";

    if (activeTab === tabName) {
      className += " tab-list-active";
    }

    return (
      <li onClick={this.handleTabClick} className={className} key={tabName}>
        {tabName}
      </li>
    );
  }
}

export default Tab;
