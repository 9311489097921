import React, { Component } from "react";
import Tab from "./Tab";

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.tabList[0].tabName,
    };
  }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  render() {
    let { tabList } = this.props;
    let { activeTab } = this.state;
    let indx = 0,
      hasTab = false;

    tabList.map((tb) => {
      if (tb.tabName === activeTab) {
        hasTab = true;
      }
    });

    if (!hasTab) {
      this.setState({ activeTab: tabList[0].tabName });
    }

    let dataToShow;
    tabList.map((currentTab) => {
      if (currentTab.tabName === activeTab) {
        dataToShow = currentTab.children;
      }
    });

    return (
      <div className="tabs" style={{ width: "100%", height: "100%" }}>
        <ol className="tabs-list pl-0 mb-0">
          {tabList.map((currentTab) => {
            return (
              <Tab
                activeTab={activeTab}
                tabName={currentTab.tabName}
                handleTabClick={this.handleTabClick}
                key={indx++}
              />
            );
          })}
        </ol>
        <div className="tab-content">{dataToShow}</div>
      </div>
    );
  }
}
export default Tabs;
